import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { black, white } from 'shared/colors';
import HighTalesSection from 'components/HighTales/HighTalesSection';
import { convertToVW } from 'shared/utils';
import { BREAKPOINTS } from 'shared/constants';
const bg = require('images/herov2.jpg');

const FAQPage = () => {
  return (
    <main className={css(styles.container)}>
      <HighTalesSection />
      <div className={css(styles.productCtaContainer)}>
        <img
          id="herov2"
          className={css(styles.productCta)}
          src={bg}
          alt="Reviewed by Accessible 360"
        />
        <div className={css(styles.textWrapper)}>
          <p className={css(styles.subheader)}>HYPE WILLIAMS X SLIM AARONS</p>
          <h1 className={css(styles.title)}>THE GOOD LIFE, REDEFINED</h1>
          <div className={css(styles.buttonWrapperHardcode)}>
            <a href="/editorial/thegoodliferedefined">
              <button className={css(styles.buttonHardcode)}>
                <p className={css(styles.buttonTextHardcode)}>
                  view behind the scenes
                </p>
              </button>
            </a>
          </div>
        </div>
      </div>
    </main>
  );
};

export default FAQPage;

const styles = StyleSheet.create({
  container: {
    backgroundColor: black,
    color: white,
    paddingTop: convertToVW(190.5),
    [BREAKPOINTS.TABLET]: {
      paddingTop: '8rem',
    },
    [BREAKPOINTS.MOBILE]: {
      paddingTop: '4rem',
    },
  },
  productCta: {
    display: 'block',
    filter: 'brightness(0.5)',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  productCtaContainer: {
    borderTop: '1px solid white',
    position: 'relative',
    backgroundColor: black,
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: 'calc(100vw * 9 / 16)',

    [BREAKPOINTS.TABLET]: {
      height: '100vw',
    },
  },
  textWrapper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    textAlign: 'center',

    [BREAKPOINTS.TABLET]: {
      width: 'calc(100vw - 80px)',
    },
  },
  subheader: {
    position: 'relative',
    fontSize: 12,
    fontFamily: 'Titling Gothic FB Wide',
    color: white,
    lineHeight: '12px',
    textTransform: 'uppercase',
    paddingBottom: '20px',
    [BREAKPOINTS.TABLET]: {
      fontSize: 8,
    },
  },
  title: {
    color: white,
    fontFamily: 'Titling Gothic FB Compressed Standard',
    textTransform: 'uppercase',
    fontSize: '70px',
    lineHeight: '70px',
    paddingBottom: '20px',
    [BREAKPOINTS.MOBILE]: {
      fontSize: 40,
      lineHeight: 1,
    },
  },
  buttonWrapperHardcode: {
    position: 'relative',

    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',

    [BREAKPOINTS.MOBILE]: {
      margin: '-1rem auto',
      transform: 'scale(0.75)',
    },
  },
  buttonHardcode: {
    color: black,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    alignSelf: 'center',
    height: convertToVW(123),
    width: '360px',
    maxWidth: '450px',
    maxHeight: '100px',
    minHeight: '70px',
    border: '1px solid white',
    cursor: 'pointer',
    backgroundColor: 'white',
    transition: 'all 0.3s ease-in-out',
    [BREAKPOINTS.FOOTER_BREAK_12]: {
      width: '450px',
    },
    [BREAKPOINTS.FOOTER_BREAK_95]: {
      width: '270px',
    },
  },
  buttonTextHardcode: {
    fontSize: 12,
    color: black,
    fontFamily: 'Titling Gothic FB Wide',
    [BREAKPOINTS.TABLET]: {},
  },
});
